$accent-colour: #0099ff;
$dark-accent: #494949;

body {
    font: 11pt verdana;
    background-color: white;
    color: black;
    margin: 0;
    padding: 0;
}

.content {
    max-width: 55rem;
    margin: 0 auto;
    padding: 2em;
}

.title {
    background-color: $accent-colour;
    color: white;

    h1 {
        font-size: 60pt;
        color: inherit;
        letter-spacing: inherit;
        margin: 0;

        @media all and (max-width: 40em)
        {
            font-size: 40pt;
        }
    }

    #logininfo {
        float: right;
        margin: 0 0 1em 2em;
    }

    #logininfo, #crumb {
        font-size: 12pt;
    }

    #crumb {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
        }

        li:not(:last-child):after
        {
            content: ' >\00a0';
            white-space: normal;
        }

        a:hover {
            color: $dark-accent;
        }
    }
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    img {
        display: block;
        width: 14em;
        height: 1.4em;

        @media all and (max-width: 30em)
        {
            width: 11em;
            height: 1.1em;
        }
    }
}

nav {
    text-align: right;

    h1 {
        display: none;
    }

    ul {
        margin: 0;
        padding: 0;
        font-size: 12pt;
    }

    li {
        display: inline-block;
        list-style-type: none;
        margin: 0 0 0 .8em;
    }

    a {
        color: $dark-accent;
        text-decoration: none;
    }

    a:hover {
        border-bottom: 1px solid $accent-colour;
    }

    .sel {
        color: $accent-colour;
    }
}

footer {
    background-color: $dark-accent;
    box-shadow: 0 50vh 0 50vh $dark-accent;

    .content {
        font-size: 9pt;
        color: #cccccc;
    }
}

h1 {
    font: 20pt arial;
    color: $accent-colour;
    font-weight: normal;
    letter-spacing: 0.07em;
    margin-bottom: 0.8em;
}

h2 {
    color: $accent-colour;
    font: 16pt arial;
    font-weight: normal;
    letter-spacing: 0.03em;
    margin-bottom: 0.5em;
}

img {
    border: 0;
}

a {
    color: inherit;
}

a:hover {
    color: $accent-colour;
}

.large {
    font-size: 1.2em;
}

table {
    border-collapse: collapse;
    margin-bottom: 1em;
}

table.fullwidth {
    width: 100%;
}

th, td {
    text-align: left;
    border-left: 1px solid #eeeeee;
    padding: 0.3em 0.7em 0.3em 0.7em;
}

td {
    border-top: 1px solid #eeeeee;
}

td:first-of-type, th:first-of-type {
    padding-left: 0;
    border-left: 0;
}

input, select, textarea {
    font: inherit;
}

input[type="submit"] {
    margin-top: 0.35em;
    padding: 0.5em;
    margin-right: 0.5em;
}

input[type="submit"].default {
    font-weight: bold;
}

.about-us {
    display: flex;
    align-items: center;

    img {
        order: 1;
        margin-left: 2em;
        max-width: 100%;
        flex-shrink: 0;
    }

    @media all and (max-width: 50em)
    {
        display: block;

        img {
            display: block;
            margin: 0 auto;
        }
    }
}

form.contact {
    display: grid;
    grid-template-columns: max-content max-content 25em;
    grid-gap: 1em;

    label {
        grid-column: 1;
        font-size: 1.1em;
    }

    input, select, textarea {
        grid-column: 2 / 4;
        margin: 0;
    }

    textarea {
        box-sizing: border-box;
        min-width: 100%;
        max-width: 100%;
        min-height: 20em;
    }

    input[type="submit"] {
        grid-column: 2;
    }

    @media all and (max-width: 45em),
        (-ms-high-contrast: active),  // IE11 doesn't support CSS grid, so
        (-ms-high-contrast: none)     // use flexbox all the time for it
    {
        display: flex;
        flex-direction: column;

        label, input[type="submit"] {
            margin-top: 1em;
        }
    }
}

/* Error reporting specific styles */

pre {
    font: 9pt "Consolas", "Lucida Console", monospace;
    overflow: auto;
    max-height: 800px;
}

.resolved {
    color: #aaa;
}

.resolved a {
    color: #aaa;
}

.status-open {
    color: #43A047;
    background-color: #C8E6C9;
    padding: 0.1em 0.4em;
    border: 1px solid #43A047;
    border-radius: 0.3em;
}

.status-closed {
    color: #757575;
    background-color: #E0E0E0;
    padding: 0.1em 0.4em;
    border: 1px solid #757575;
    border-radius: 0.3em;
}
